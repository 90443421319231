import React, { useState, useEffect, useRef } from 'react'
import {
  HeaderWrapper,
  Menu,
  List,
  ListItem,
  Item,
  HeaderLogoWrapper,
  HeaderLogo,
  ButtonMenu,
  Close
} from './index.styled'
import { links } from './links'
import useWindowSize from 'styles/use-window-size'

const Header = () => {
  const [isMore10vh, setIsMore10vh] = useState(0)
  const [isScrolled, setIsScrolled] = useState(false)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const { width } = useWindowSize()

  const menuRef = useRef(null)
  const headerLogoRef = useRef(null)
  const buttonMenuRef = useRef(null)

  const menu = menuRef.current
  const headerLogo = headerLogoRef.current
  const buttonMenu = buttonMenuRef.current

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset
      const scrolled10vh = window.scrollY > window.innerHeight / 10
      setIsMore10vh(scrolled10vh)
      setIsScrolled(lastScrollTop > currentScrollTop)
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollTop])

  const onClick = () => {
    menu.classList.toggle('open')
    headerLogo.classList.toggle('active')
    buttonMenu.classList.toggle('active')
  }

  const onClickItem = (url) => {
    if (window.location.pathname === url) {
      return
    }
    menu.classList.remove('open')
    headerLogo.classList.remove('active')
    buttonMenu.classList.remove('active')
  }

  return (
    <HeaderWrapper
      className={[isMore10vh ? 'scrolled' : '', isScrolled ? 'show' : ''].join(' ')}
    >
      <Menu id='menu' ref={menuRef}>
        <List>
          {links.map((item, index) => {
            return (
              <ListItem key={index}>
                <Item
                  to={item.url}
                  onClick={width <= 768 ? () => onClickItem(item.url) : null}
                >
                  {item.text}
                </Item>
              </ListItem>
            )
          })}
        </List>
      </Menu>
      <HeaderLogoWrapper ref={headerLogoRef}>
        <HeaderLogo to='/' />
      </HeaderLogoWrapper>
      <ButtonMenu
        onClick={onClick}
        className='button-menu'
        ref={buttonMenuRef}
      >
        Menu<Close>Close</Close>
      </ButtonMenu>
    </HeaderWrapper>
  )
}

export default Header
