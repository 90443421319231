import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  mediaMax,
  calculateResponsiveSize,
  calculateMobileResponsiveSize
} from 'styles/media-queries'
import LogoImg from 'icons/logo_small.svg'

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: ${calculateResponsiveSize(15)} ${calculateResponsiveSize(40)};
  mix-blend-mode: difference;
  z-index: 10;
  ${mediaMax('mobile')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${calculateMobileResponsiveSize(13)} ${calculateMobileResponsiveSize(20)};
    mix-blend-mode: inherit;
  }
`

export const Menu = styled.nav`
  transform: translateY(0);
  transition: transform 1s 0.5s;
  .scrolled & {
    transform: translateY(-150%);
  }
  .scrolled.show & {
    transform: translateY(0);
  }
  ${mediaMax('mobile')} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 0;
    padding: ${calculateMobileResponsiveSize(20)} ${calculateMobileResponsiveSize(23)};
    background: #c7c8ca;
    opacity: 0;
    visibility: hidden;
    transform: translateY(0) !important;
    transition: height 0s ease 0.5s, opacity 0.5s ease, visibility 0.5s ease;
    &.open {
      height: 100dvh;
      opacity: 1;
      visibility: visible;
      transition: height 0s ease 0s, opacity 0.5s ease, visibility 0.5s ease;
    }
  }
`

export const List = styled.ul`
  display: flex;
  gap: ${calculateResponsiveSize(100)};
  margin: 0;
  padding: 0;
  list-style: none;
  ${mediaMax('mobile')} {
    flex-direction: column;
    margin: auto 0;
    gap: ${calculateMobileResponsiveSize(14)};
  }
`

export const ListItem = styled.li`
  font-size: ${calculateResponsiveSize(30)};
  color: #4d4d4f;
  font-weight: 700;
  line-height: 1.2;
  &:nth-last-child(2) {
    margin-left: auto;
  }
  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(60)};
    font-weight: 700;
    line-height: ${calculateMobileResponsiveSize(60)};
    letter-spacing: -0.01em;

    &:nth-last-child(2) {
      margin-left: 0;
      position: absolute;
      bottom: ${calculateMobileResponsiveSize(23)};
      left: ${calculateMobileResponsiveSize(20)};
      font-size: ${calculateMobileResponsiveSize(24)};
      line-height: ${calculateMobileResponsiveSize(30)};
    }
    &:nth-last-child(1) {
      position: absolute;
      bottom: ${calculateMobileResponsiveSize(23)};
      right: ${calculateMobileResponsiveSize(20)};
      font-size: ${calculateMobileResponsiveSize(24)};
      line-height: ${calculateMobileResponsiveSize(30)};
    }
  }
`

export const Item = styled((props) => <Link {...props} />)`
  text-decoration: none !important;
  color: #bababa;
  ${mediaMax('mobile')} {
    color: #4d4d4f;
  }
`

export const HeaderLogoWrapper = styled.div`
  ${mediaMax('mobile')} {
    transform: translateY(0);
    transition: transform 1s 0.5s;
    .scrolled &:not(.active) {
      transform: translateY(-150%);
    }
    .scrolled.show &:not(.active) {
      transform: translateY(0);
    }
  }
`

export const HeaderLogo = styled((props) => <Link {...props} />)`
  position: absolute;
  top: calc(100vh - ${calculateResponsiveSize(40)});
  right: ${calculateResponsiveSize(40)};
  width: ${calculateResponsiveSize(26)};
  height: ${calculateResponsiveSize(66)};
  background: #bababa;
  mask-position: left top;
  mask-repeat: no-repeat;
  mask-size: 100% auto;
  mask-image: url(${LogoImg});
  -webkit-mask-position: left top;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100% auto;
  -webkit-mask-image: url(${LogoImg});
  transform: translateY(-100%);
  z-index: 4;
  ${mediaMax('mobile')} {
    position: static;
    display: block;
    width: ${calculateMobileResponsiveSize(14)};
    height: ${calculateMobileResponsiveSize(35)};
    background: #4d4d4f;
    transform: translateX(0px);
  }
`

export const ButtonMenu = styled.div`
  display: none;
  ${mediaMax('mobile')} {
    display: flex;
    font-size: ${calculateMobileResponsiveSize(24)};
    font-weight: 700;
    line-height: ${calculateMobileResponsiveSize(30)};
    transition: color 0.5s ease;
    position: relative;
    z-index: 1;
    &.active {
      color: #c7c8ca;
    }
    transform: translateY(0);
    transition: transform 1s 0.5s;
    .scrolled &:not(.active) {
      transform: translateY(-150%);
    }
    .scrolled.show &:not(.active) {
      transform: translateY(0);
    }
  }
`

export const Close = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4d4d4f;
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 1;
  cursor: pointer;
  .active & {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
`
