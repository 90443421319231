import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollSmoother from 'gsap/ScrollSmoother'
import Flip from 'gsap/Flip'
gsap.registerPlugin(ScrollTrigger, ScrollSmoother)
gsap.registerPlugin(Flip)

ScrollTrigger.config({
  limitCallbacks: true,
  ignoreMobileResize: true
})

export * from 'gsap'
export * from 'gsap/ScrollTrigger'
export * from 'gsap/ScrollSmoother'
export * from 'gsap/Flip'
